<template>
    <div class="market">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="head_title">Market</div>
            <div class="r">
                <router-link to="/market/help">
                    <div class="btn">Learn More</div>
                </router-link>
            </div>
        </div>
        <div class="main">
            <wallet/>

            <div class="grid">
                <div @click="soon()">
                    <div class="icon">
                        <airtimeicon :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    <div class="text">Airtime</div>
                </div>
                <div @click="soon()">
                    <div class="icon">
                        <dataicon :size="'20px'"/>
                    </div>
                    <div class="text">Data</div>
                </div>
                <div @click="soon()">
                    <div class="icon">
                        <tvicon :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    <div class="text">Cable TV</div>
                </div>
                <div @click="soon()">
                    <div class="icon">
                        <electricityicon :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    <div class="text">Electricity</div>
                </div>
            </div>
            <airtime v-if="current == 'a'"/>
            <databox v-if="current == 'd'"/>
        </div>
        <menulayout class="fixed"/>
    </div>
</template>

<script>
import back from '../../icons/back.vue'

import wallet from '../../components/market/wallet.vue';


import airtimeicon from '../../icons/airtime.vue'
import dataicon from '../../icons/data.vue'
import tvicon from '../../icons/tv.vue'
import electricityicon from '../../icons/electricity.vue';

import menulayout from '../../components/menu.vue';

export default {
    components: {
        back,
        wallet,
        airtimeicon, dataicon, tvicon, electricityicon,
        menulayout
    },
    data() {
        return {
            current: 'a'
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        soon() {
            this.coming_soon()
        }
    },
    notifications: {
        coming_soon: {
            type: 'info',
            title: 'Coming Soon',
            message: 'This feature will be available in the next update'
        }
    }
}
</script>

<style scoped>

    .market {
        background-color: white;
    }
    .head {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);

        position: relative;
    }
    .head_title {
        font-weight: 500;
    }
    .r {
        position: absolute;
        right: 15px;
    }
    .btn {
        font-size: 10px;
        background-color: var(--main);
        color: var(--trans);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        padding: 7px 10px;
    }


    .main {
        height: calc(100vh - 111px);
        overflow: auto;
        box-sizing: border-box;
    }

    .grid {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        margin-top: 30px;
        text-align: center;
        fill: #686868;
        color: gray;
    }
    
    .icon {
        text-align: center;
        fill: var(--main);
        margin: 0 auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
    }
    .text {
        font-size: 12px;
        font-weight: 500;
        margin-top: 3px;
        text-align: center;
    }
    .a {
        color: var(--main);
        fill: var(--main);
        font-weight: 500;
    }
</style>