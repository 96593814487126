<template>
    <div>
        <div class="wallet">
            <div class="title">My Wallet</div>
            <div class="tx" @click="soon()">Transactions</div>
            <div class="balance">
                ₦ 0.00
            </div>
        </div>
        <div class="box">
            <div class="item" @click="soon()">
                <fund :size="'25px'"/>
                <div class="text">Fund Wallet</div>
            </div>
            <div class="item" @click="soon()">
                <coupon :size="'25px'"/>
                <div class="text">My Coupons</div>
            </div>
            <div class="item" @click="soon()">
                <order :size="'25px'"/>
                <div class="text">My Orders</div>
            </div>
        </div>
    </div>
</template>

<script>
import fund from '../../icons/fund.vue'
import coupon from '../../icons/coupon.vue';
import order from '../../icons/order.vue'

export default {
    components: {
        fund, coupon, order
    },
    methods: {
        soon() {
            this.coming_soon()
        }
    },
    notifications: {
        coming_soon: {
            type: 'info',
            title: 'Coming Soon',
            message: 'This feature will be available in the next update'
        }
    }
}
</script>

<style scoped>
    .wallet {
        padding: 80px 15px;
        padding-bottom: 90px;
        /* background: linear-gradient(to top right, var(--trans), rgba(65, 105, 225, 0.3)); */
        /* color: white; */
        /* border-radius: 10px; */
        background-color: var(--trans);
        position: relative;
    }
    .title {
        position: absolute;
        top: 20px;
        left: 15px;
        font-weight: 700;
        font-size: 15px;
    }
    .tx {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: white;
        color: var(--main);
        padding: 5px 10px;
        border: 5px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 5px;
    }
    .balance {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
    }

    .box {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        margin: 15px;
        margin-top: -50px;
        padding: 15px;
        border-radius: 5px;

        display: flex;
        justify-content: space-around;

        position: relative;
        z-index: 2;
    }
    .item {
        fill: #555;
        text-align: center;
    }
    .text {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
</style>